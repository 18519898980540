import React from "react"
import Layout from "../components/layoutLogin"
import ForgotPassword from "../components/forgotPassword"
import SEO from "../components/seo"

const LoginPage = () => (
  <Layout>
    <SEO title="Recuperar contraseña" />
    <ForgotPassword/>
  </Layout>
)

export default LoginPage
